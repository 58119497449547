import React from "react";
import { Helmet } from "react-helmet";

export const BaseMeta = ({
    robotsContent,
    canonical,
    title,
    description,
    structuredData,
    prevPage,
    nextPage,
    children
}) => {
    const metaDescription =
        description ||
        "The most diverse collection of icons ever. Download SVG and PNG. Millions of free icons or unlimited royalty-free icons with NounPro.";

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            {!!structuredData && (
                <script
                    className="structured-data-list"
                    type="application/ld+json"
                >
                    {JSON.stringify(structuredData)}
                </script>
            )}

            <meta name="robots" content={robotsContent} />
            {!!canonical && <link rel="canonical" href={canonical} />}
            {!!prevPage && <link rel="prev" href={prevPage} />}
            {!!nextPage && <link rel="next" href={nextPage} />}

            {children}
        </Helmet>
    );
};

export default BaseMeta;
