import React, { useEffect } from "react";

import { PROD_BASE_URL } from "core/constants";
import BaseMeta from "./BaseMeta";
import SocialMeta from "./SocialMeta";

export const PageMeta = ({
    title,
    description,
    useSocialMediaTags, // (bool) include facebook & twitter meta tags
    previewImg, // (string) image to show on facebook & twitter share links
    previewImgSize, // (number, string, object) image size, example: 200 or "200" will be treated as 200x200 (square). Or customize dimensions via `{ width: 100, height: 200 }`
    previewImgAlt, // (string) alt text of image file
    twitterTitle, // (string) custom twitter title; if absent, `title` is used
    twitterDescription, // (string) custom twitter description; if absent, `description` is used
    twitterCardContent, // (string) "summary", "summary_large_image", etc.
    noindex, // (bool) if `true`, bots will crawl urls on the page but exclude this page from search results
    indexContent, // (string) custom robots meta tag value
    structuredData, // (object) ld+json schema
    canonicalURL, // (string) ideal page url (google will then ignore alternative url variations, ish lol)
    prevPage, // (string) url to previous paginated results page (exclude if on page 1)
    nextPage, // (string) url to next paginated results page (exclude if on last page)
    children
}) => {
    // Remove seo-related noscript content
    useEffect(() => {
        const noscriptContent = document.getElementById("content_summary");
        if (noscriptContent) {
            noscriptContent.remove();
        }
    }, []);

    const canonical = canonicalURL
        ? canonicalURL
        : typeof window === "undefined"
        ? ""
        : PROD_BASE_URL + window.location.pathname;

    const robotsContent = noindex ? "noindex" : indexContent || "index,follow";

    return (
        <>
            <BaseMeta
                robotsContent={robotsContent}
                canonical={canonical}
                title={title}
                description={description}
                structuredData={structuredData}
                prevPage={prevPage}
                nextPage={nextPage}
            >
                {children}
            </BaseMeta>
            {!!useSocialMediaTags && (
                <SocialMeta
                    title={title}
                    description={description}
                    canonical={canonical}
                    previewImg={previewImg}
                    previewImgSize={previewImgSize}
                    previewImgAlt={previewImgAlt}
                    twitterTitle={twitterTitle}
                    twitterDescription={twitterDescription}
                    twitterCardContent={twitterCardContent}
                />
            )}
        </>
    );
};

export default PageMeta;
