import React from "react";

// content for page hero text, images, secondary hero text:
import AboutHeroDesktopImg from "./images/about-hero-desktop.png";
import AboutHeroTabletImg from "./images/about-hero-tablet.png";
import AboutHeroMobileImg from "./images/about-hero-mobile.png";

export const heroImageDesktop = AboutHeroDesktopImg;
export const heroImageTablet = AboutHeroTabletImg;
export const heroImageMobile = AboutHeroMobileImg;

export const heroTitle =
    "We believe visual language has the power to change the world.";
export const secondaryHeroText =
    "Noun Project is building a global visual language that unites us — a language that allows quick and easy communication no matter who you are or where you are.";

// content for Icon collection spotlight:
import aboutIconCollectionSpotlightCreativeStallLorieShaullImg from "./images/about-icon-collection-spotlight-creativeStall-lorieShaull.png";

export const iconCollectionImage = aboutIconCollectionSpotlightCreativeStallLorieShaullImg;
export const iconCollectionCaption = "Artists: Creative Stall & Lorie Shaull";
export const iconCollectionAlt = "Artists: Creative Stall & Lorie Shaull";

const iconCollectionHeadingText = (
    <>
        The Most Comprehensive <br /> Icon Collection in the{" "}
        <br className="mobile-only-break" /> World
    </>
);

export const iconCollectionHeading = iconCollectionHeadingText;

const secondParagraphIconCollectionText = (
    <>
        Icons are some of the most universal communication tools available,
        crossing language and cultural boundaries. From icons for{" "}
        <a href="/search/collection/?q=ai" target="_blank">
            Artificial Intelligence
        </a>
        , to{" "}
        <a href="/search/collection/?q=ui" target="_blank">
            UI elements
        </a>{" "}
        and{" "}
        <a href="/search/?q=beyonce" target="_blank">
            Beyoncé
        </a>
        , we've got you covered.
    </>
);

export const iconCollectionText = [
    "Our community of designers from 120+ countries is building the most diverse and extensive collection of iconography ever created.",
    secondParagraphIconCollectionText
];

// content for Photo collection spotlight:
import aboutPhotoCollectionSpotlightImg from "./images/about-photo-collection-spotlight.png";

export const photoCollectionImage = aboutPhotoCollectionSpotlightImg;
export const photoCollectionCaption = "Photo by: Jacob Lund";
export const photoCollectionAlt = "Photo by: Jacob Lund";

const photoCollectionHeadingText = (
    <>
        A Mission-Driven
        <br /> Collection of Art <br className="mobile-only-break" /> Quality
        Photos
    </>
);

export const photoCollectionHeading = photoCollectionHeadingText;

const secondParagraphPhotoCollectionText = (
    <>
        Because representation is a fundamental part of equality.{" "}
        <a
            href="https://blog.thenounproject.com/introducing-noun-project-photos"
            target="_blank"
        >
            Read more
        </a>
        .
    </>
);

export const photoCollectionText = [
    "Our global community of photographers is building a professionally curated collection of authentic, beautiful images that celebrate diversity and represent a more inclusive world.",
    secondParagraphPhotoCollectionText
];
// content for Timeline:
export const timelineTitle = (
    <>
        From bootstrapped launch to
        <br /> 10 million global members.
    </>
);

const timelineText2010 = (
    <>
        Noun Project launches on Kickstarter in December 2010 to build a{" "}
        <a href="https://vimeo.com/48846655" target="_blank">
            global visual language that unites the world
        </a>
        .
    </>
);

const timelineText2011 = (
    <>
        Noun Project hosts{" "}
        <a
            href="/browse/creator/Iconathon1/icon-collections/?p=1"
            target="_blank"
        >
            Iconathons
        </a>{" "}
        across the country to create civic icons for topics like human rights
        and disaster preparedness.
    </>
);
const timelineText2014 = (
    <>
        Noun Project is funded by mission-driven{" "}
        <a
            href="https://medium.com/@sofyapolyakov/my-awesome-experience-starting-a-family-while-running-a-vc-backed-startup-766d17d72d18"
            target="_blank"
        >
            investors
        </a>{" "}
        like{" "}
        <a href="https://www.collaborativefund.com/" target="_blank">
            Collaborative Fund
        </a>
        ,{" "}
        <a href="https://lowercasecapital.com/" target="_blank">
            Lowercase
        </a>
        ,{" "}
        <a href="https://www.designerfund.com/" target="_blank">
            Designer Fund
        </a>
        , &amp;{" "}
        <a href="https://www.scottbelsky.com/" target="_blank">
            Scott Belsky
        </a>
        .
    </>
);
const timelineText2016 = (
    <>
        Noun Project launches{" "}
        <a href="https://www.lingoapp.com/" target="_blank">
            Lingo
        </a>{" "}
        - a digital asset manager that enables teams to visually organize and
        share brand assets.
    </>
);
const timelineText2017 = (
    <>
        New initiatives like the{" "}
        <a
            href="https://blog.thenounproject.com/the-redefining-women-icon-collection"
            target="_blank"
        >
            Redefining Women
        </a>{" "}
        collection set new standards for inclusive representation in
        iconography.
    </>
);
const timelineText2020 = (
    <>
        Noun Project launches art-quality photos{" "}
        <a
            href="https://blog.thenounproject.com/introducing-noun-project-photos"
            target="_blank"
        >
            celebrating diversity
        </a>{" "}
        and representing a more inclusive world.
    </>
);

export const timelineContent = [
    {
        title: "2010",
        subtitle: "A New Global Visual Language",
        text: timelineText2010
    },
    {
        title: "2011",
        subtitle: "Building an Extensive Collection",
        text: timelineText2011
    },
    {
        title: "2014",
        subtitle: "Accelerating Growth",
        text: timelineText2014
    },
    {
        title: "2016",
        subtitle: "A Home for Your Brand",
        text: timelineText2016
    },
    {
        title: "2017",
        subtitle: "Championing Inclusion",
        text: timelineText2017
    },
    {
        title: "2020",
        subtitle: "Mission-Driven Photo Collection",
        text: timelineText2020
    }
];

// content for Testimonial:
export const testimonials = [
    {
        text:
            "Once again, @nounproject takes steps to promote a more gender-balanced world through iconography depicting women in leadership positions. I can't say enough positive things about this company.",
        source: "— @empressgui"
    }
];

// for Leadership:
import sofyaImg from "./images/about-sofya-polyakov.jpg";
import edwardImg from "./images/about-edward-boatman.jpg";
import XiaolingImg from "./images/XZ.jpg";

import Website from "core/ReusableIcons/Website";
import Medium from "core/ReusableIcons/Medium";
import LinkedIn from "core/ReusableIcons/Linkedin";

import { CAREERS_PATH } from "data/links";

export const leadershipTitle = "Meet Our Leadership";

export const leadershipTeam = [
    {
        title: "Sofya Polyakov",
        subtitle: "Chief Executive Officer, Founder",
        imgSrc: sofyaImg,
        links: [
            {
                url: "https://www.sofyapolyakov.com/",
                icon: Website,
                altInfo: "Sofya Polyakov - Website"
            },
            {
                url: "https://medium.com/@sofyapolyakov",
                icon: Medium,
                altInfo: "Sofya Polyakov - Medium blog"
            },
            {
                url: "https://www.linkedin.com/in/sofya-polyakov-8b883715/",
                icon: LinkedIn,
                altInfo: "Sofya Polyakov - LinkedIn profile"
            }
        ]
    },
    {
        title: "Edward Boatman",
        subtitle: "CEO of Lingo, Founder",
        imgSrc: edwardImg,
        links: [
            {
                url: "https://medium.com/@edwardboatman",
                icon: Medium,
                altInfo: "Edward Boatman - Medium blog"
            },
            {
                url: "https://www.linkedin.com/in/edward-boatman-341a3b17/",
                icon: LinkedIn,
                altInfo: "Edward Boatman - LinkedIn profile"
            }
        ]
    },
    {
        title: "Xiaoling Zhan",
        subtitle: "Chief Technology Officer",
        imgSrc: XiaolingImg,
        links: [
            {
                url: "https://www.linkedin.com/in/xiaolingzhan/",
                icon: LinkedIn,
                altInfo: "Xiaoling Zhan - LinkedIn profile"
            }
        ]
    }
];

export const leadershipButton = {
    text: "Meet Our Team",
    url: CAREERS_PATH
};

// for Company Values:

export const companyValuesTitle = "Company Values";

export const companyValues = [
    {
        title: "People First",
        text:
            "We believe people are our biggest asset. We prioritize a healthy work-life balance and putting family first."
    },
    {
        title: "Respect and Kindness",
        text:
            "We expect everyone on our team to treat others, as well as themselves, with the highest level of respect and kindness."
    },
    {
        title: "Honesty, Transparency, Fairness",
        text:
            "We believe that acting with utmost integrity at all times is always the best choice for long-term success."
    },
    {
        title: "Diversity and Inclusion",
        text:
            "From hiring to curation practices, we are committed to upholding the highest standards for diversity and inclusion."
    },
    {
        title: "Learn and Improve",
        text:
            "We are constantly learning and working to improve ourselves, our team, our product, and our processes."
    },
    {
        title: "Keep It Simple",
        text:
            "We value efficient processes, clear ideas, simple and delightful product features, and deliberate decision-making."
    }
];

// for Press section:
const npText = (
    <>
        For press inquires, please contact lindsay@thenounproject.com
        <br />
        For a full list of assets, please reference our Lingo press kit.
    </>
);

export const nounProjectPressTitle = "Noun Project Press";

export const nounProjectPressSubtext = npText;

export const nounProjectPressButton = [
    {
        text: "Noun Project Press Kit",
        url:
            "https://www.lingoapp.com/6/k/6A90F478-575D-4F32-8019-F27331DA7A09",
        target: "_blank"
    }
];
