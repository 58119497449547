import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { linkTargets } from "core/props";

import PlaceholderGif from "./images/placeholder.gif";
import { ImageContainer, PlaceholderImage } from "./styles";

const UnstyledImagePreventRightClick = ({
    className,
    srText,
    imageSrc,
    imageAttributes = null,
    href = false,
    target = "_self",
    usePlaceholder = false,
    preventPointerEvents = false,
    backgroundSize,
    backgroundPosition,
    children
}) => {
    // if href is passed, render an anchor tag, else render div:
    const imageContainerElementType = href ? "a" : "div";

    // support both webpack (string) & next.js (object) image imports
    const src = !!imageSrc && imageSrc.src ? imageSrc.src : imageSrc;
    const placeholder =
        !!PlaceholderGif && PlaceholderGif.src
            ? PlaceholderGif.src
            : PlaceholderGif;

    // return props in relation to href if href exists, else default props:
    const containerProps = href
        ? {
              href,
              target,
              className
          }
        : {
              className
          };

    return (
        <ImageContainer
            {...containerProps}
            as={imageContainerElementType}
            usePlaceholder={usePlaceholder}
            preventPointerEvents={preventPointerEvents}
            title={srText}
        >
            {children}
            {/* use placeholder when space needs to be reserved for scaling, ex: home page collections tiles: */}
            {usePlaceholder ? (
                <PlaceholderImage
                    src={placeholder}
                    source={src}
                    backgroundSize={backgroundSize}
                    backgroundPosition={backgroundPosition}
                    alt={srText}
                />
            ) : (
                <img src={src} alt={srText} {...imageAttributes} />
            )}
        </ImageContainer>
    );
};

const ImagePreventRightClick = styled(UnstyledImagePreventRightClick)``;

ImagePreventRightClick.propTypes = {
    className: PropTypes.string,
    srText: PropTypes.string.isRequired,
    imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    imageAttributes: PropTypes.object,
    href: PropTypes.string,
    target: PropTypes.oneOf(linkTargets),
    usePlaceholder: PropTypes.bool,
    preventPointerEvents: PropTypes.bool,
    backgroundSize: PropTypes.string,
    backgroundPosition: PropTypes.string
};

export default ImagePreventRightClick;
