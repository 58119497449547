import React from "react";
import { Helmet } from "react-helmet";

const SocialMeta = ({
    title,
    description,
    canonical,
    previewImg,
    previewImgSize, // ex: 300 or { width: 200, height: 300 }
    previewImgAlt,
    twitterTitle,
    twitterDescription,
    twitterCardContent
}) => {
    previewImg = String(previewImg || "");
    if (!previewImg) {
        previewImgSize = null;
        previewImgAlt = "";
    }
    const previewImageType = previewImg.endsWith(".png")
        ? "image/png"
        : previewImg.endsWith(".jpg") || previewImg.endsWith(".jpeg")
        ? "image/jpeg"
        : previewImg.endsWith(".svg")
        ? "image/svg+xml"
        : null;
    const previewImgWidth =
        parseInt(previewImgSize?.width || previewImgSize) || 0;
    const previewImgHeight =
        parseInt(previewImgSize?.height || previewImgSize) || 0;

    twitterCardContent = twitterCardContent || "summary";
    twitterTitle = twitterTitle || title;
    twitterDescription = twitterDescription || description;

    return (
        <Helmet>
            <meta property="og:url" content={canonical} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="The Noun Project" />
            <meta property="og:type" content="website" />
            {!!previewImg && <meta property="og:image" content={previewImg} />}
            {previewImg.startsWith("https") && (
                <meta property="og:image:secure_url" content={previewImg} />
            )}
            {!!previewImageType && (
                <meta property="og:image:type" content={previewImageType} />
            )}
            {previewImgWidth > 0 && (
                <meta property="og:image:width" content={previewImgWidth} />
            )}
            {previewImgHeight > 0 && (
                <meta property="og:image:height" content={previewImgHeight} />
            )}
            {!!previewImgAlt && (
                <meta property="og:image:alt" content={previewImgAlt} />
            )}

            <meta name="twitter:card" content={twitterCardContent} />
            <meta name="twitter:site" content="@nounproject" />
            <meta name="twitter:title" content={twitterTitle} />
            <meta name="twitter:description" content={twitterDescription} />
            <meta name="twitter:domain" content="https://thenounproject.com" />
            {!!previewImg && (
                <meta name="twitter:image:src" content={previewImg} />
            )}
            {!!previewImgAlt && (
                <meta name="twitter:image:alt" content={previewImgAlt} />
            )}
        </Helmet>
    );
};

export default SocialMeta;
